import React from 'react';
import Slideheader from './Slideheader';
import courses from '../../../courses.json';
const Slide = ({
	slideId,
	title,
	description,
	icon,
	onBtnClick,
	answers,
	onBackClick,
	onRestartClick,
	lastSlide,
	className
}) => {
	return (
		<div className={`slide slide-${slideId} ${className}`}>
			<Slideheader
				title={title}
				description={description}
				icon={icon}
				slideID={slideId}
				onBackClick={onBackClick}
				onRestartClick={onRestartClick}
				lastSlide={lastSlide}
				answers={answers}
			/>

			{lastSlide ? (
				<div className='answers links'>
					{answers.map((answerId) => {
						// Find the corresponding course for the answerId
						const course = courses.find((course) => course.id === answerId);
						if (!course) return null;
						return (
							<a
								key={course.id}
								href={`${course.link}&be-course=bildungsfinder-course-${course.id}`}
								className={`course_link course-${course.id}`}
								target='_blank'
								rel='noopener noreferrer'
							>
								{course.affiliate && <span className='affiliate'>werbung</span>}
								{course.thumbnail && (
									<div className='course_link-thumbnail'>
										<img
											src={`${pluginPath}public/course-thumbnails/${course.thumbnail}`}
										/>
									</div>
								)}
								<div className='course_link-body'>
									<h4 dangerouslySetInnerHTML={{ __html: course.title }} />
									<small dangerouslySetInnerHTML={{ __html: course.description }} />
								</div>
							</a>
						);
					})}
				</div>
			) : (
				<div className='answers buttons'>
					{answers.map((answer) => {
						return (
							<button
								key={answer.answerId}
								onClick={() => onBtnClick(answer.nextSlide)}
								className={`btn btn-${answer.answerId}`}
								dangerouslySetInnerHTML={{ __html: answer.text }}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default Slide;
