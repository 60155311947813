const Slideheader = ({
	title,
	description,
	icon,
	slideID,
	onBackClick,
	onRestartClick,
	lastSlide,
	answers,
}) => {
	return (
		<header className='slide_header'>
			{slideID === 1 && (
				<div className='slide_header-first-slide'>
					<div className='header-text'>
						{icon ? (
							<img src={`${pluginPath}public/icons/${icon}`} />
						) : (
							<img src={`${pluginPath}public/icons/education.svg`} />
						)}
						<h2 dangerouslySetInnerHTML={{ __html: title }} />
					</div>
					{description && (
						<div className='header-description'>
							<p dangerouslySetInnerHTML={{ __html: description }} />
						</div>
					)}
				</div>
			)}

			{slideID !== 1 && (
				<>
					<div className='slide_header-head'>
						<div className='left'>
							<button className='btn-settings btn-back' onClick={onBackClick}>
								<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'>
									<path d='M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z' />
								</svg>
							</button>
						</div>

						{icon ? (
							<img src={`${pluginPath}public/icons/${icon}`} />
						) : (
							<img src={`${pluginPath}public/icons/education.svg`} />
						)}

						<div className='right'>
							<button
								className='btn-settings btn-restart'
								onClick={onRestartClick}
							>
								<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
									<path d='M125.7 160H176c17.7 0 32 14.3 32 32s-14.3 32-32 32H48c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32s32 14.3 32 32v51.2L97.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L125.7 160z' />
								</svg>
							</button>
						</div>
					</div>
					<div className='slide_header-body'>
						{lastSlide &&
							(answers.length > 1 ? (
								<small>Meine Vorschläge im Bereich</small>
							) : (
								<small>Mein Vorschlag im Bereich</small>
							))}
						<h2 dangerouslySetInnerHTML={{ __html: title }} />
						{slideID > 1 && (
							<p dangerouslySetInnerHTML={{ __html: description }} />
						)}
					</div>
				</>
			)}
		</header>
	);
};

export default Slideheader;
