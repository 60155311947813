import React, { useState, useEffect, useRef } from 'react';
import bildungsfinder from '../../bildungsfinder.json';
import Slide from './components/Slide';
import FirstSlide from './components/FirstSlide';

const App = ({ fileUrl }) => {
	const [currentSlideId, setCurrentSlideId] = useState(1); // Initial setzen wir den Start-Slide
	const [history, setHistory] = useState([1]); // Starte mit dem ersten Slide in der Historie
	const [direction, setDirection] = useState('right');



	const handleBtnClick = (nextSlide) => {
		setDirection('right');
		setHistory([...history, nextSlide]); // Füge die neue SlideId zum Historien-Array hinzu
		setCurrentSlideId(nextSlide); // Setze den aktuellen Slide
	};

	const handleBackClick = () => {
		if (history.length > 1) {
			setDirection('left');
			const newHistory = history.slice(0, history.length - 1);
			setHistory(newHistory);
			setCurrentSlideId(newHistory[newHistory.length - 1]);
		}
	};

	const handleRestartClick = () => {
		setDirection('left');
		setHistory([1]);
		setCurrentSlideId(1);
	};




	return (
		<div className='bildungsfinder' id='bildungsfinder'>
			{bildungsfinder.map((slide) => {
				if (slide.slideId === currentSlideId) {
					// Wähle die Komponente basierend auf der slideId
					const Component = slide.slideId === 1 ? FirstSlide : Slide;
					return (
						<Component
							key={`${slide.slideId}-${direction}`}
							slideId={slide.slideId}
							title={slide.title}
							description={slide.description}
							icon={slide.icon}
							thumbnail={slide.thumbnail} // Dieser Prop existiert nur für FirstSlide
							onBtnClick={handleBtnClick}
							onBackClick={handleBackClick}
							onRestartClick={handleRestartClick}
							answers={slide.answers}
							lastSlide={slide.lastSlide}
							className={`slide ${direction === 'right' ? 'slide-enter-right' : 'slide-enter-left'}`}
						/>
					);
				}
				return null;
			})}
		</div >
	);
};

export default App;
