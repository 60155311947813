import Slideheader from './Slideheader';

const FirstSlide = ({
	slideId,
	title,
	icon,
	thumbnail,
	onBtnClick,
	answers,
	description,
	className
}) => {
	return (
		<div className={`slide slide-${slideId} ${className}`}>
			<Slideheader
				title={title}
				description={description}
				icon={icon}
				slideID={slideId}
			/>
			<div className='thumbnail-container'>
				<img src={`${pluginPath}/public/${thumbnail}`} />
			</div>

			{answers.map((answer) => {
				return (
					<button
						onClick={() => {
							onBtnClick(answer.nextSlide);
						}}
						className={`btn btn-${answer.answerId}`}
					>
						{answer.text}
					</button>
				);
			})}
		</div>
	);
};

export default FirstSlide;
